import { createMemo, createSignal, For, Match, Show, Switch } from "solid-js";
import Dialog from "~/components/shared/Dialog";
import { Style } from "@solidjs/meta";

import type { PromoSegmentMarketing } from "~/types/drupal_jsonapi";

import "./PromosSegmentsMkg.css";
import LogoAccess from "~/img/logos/logo-access.svg";
import LogoCogedimAvantagesVertical1 from "~/img/logos/logo-cogedim-avantages-vertical-1.svg";
import LogoCogedimAvantagesVertical2 from "~/img/logos/logo-cogedim-avantages-vertical-2.svg";
import LogoCogedimSignature from "~/img/logos/logo-cogedim-signature.svg";

export default function PromosSegmentsMkg(props: {
  promosSegmentsMkg: PromoSegmentMarketing[];
}) {
  const [dialogIsActive, setDialogIsActive] = createSignal(false);

  const benefitsLegals = createMemo(() => {
    let tempString: string = "";
    props.promosSegmentsMkg.forEach((promo) => {
      let hasLegals = false;
      promo.field_benefits.forEach((benefit) => {
        if (benefit.field_legals) {
          hasLegals = true;
        }
      });

      if (hasLegals) {
        tempString +=
          `<div data-test="` +
          promo.field_segment_mkg.name.toLowerCase().replace(" ", "-") +
          `">`;
        if (promo.field_segment_mkg.name === "Avantages") {
          tempString += `<h3>Cogedim Avantages</h3>`;
        } else {
          tempString += `<h3>` + promo.field_segment_mkg.name + `</h3>`;
        }
        promo.field_benefits.forEach((benefit) => {
          if (benefit.field_legals) {
            tempString += `<p>` + benefit.field_legals + `</p>`;
          }
        });
        tempString += `</div>`;
      }
    });

    return tempString;
  });

  const benefitsStyles = createMemo(() => {
    const tempArray: string[] = [];
    props.promosSegmentsMkg.forEach((promo) => {
      promo.field_benefits.forEach((benefit) => {
        if (benefit.field_style && benefit.field_style.field_css) {
          tempArray.push(benefit.field_style.field_css);
        }
      });
    });

    return [...new Set(tempArray)].join(" ");
  });

  return (
    <>
      <section class="promos-segment-marketing" data-test="promos-segment-mkg">
        <Style>{benefitsStyles()}</Style>
        <For each={props.promosSegmentsMkg}>
          {(promo) => {
            return (
              <div
                class={`promo promo-${promo.field_segment_mkg.name.toLowerCase().replace(" ", "-")}`}
                data-test={`promo-${promo.field_segment_mkg.name.toLowerCase().replace(" ", "-")}`}
              >
                <p class="segment" data-test="segment">
                  <Switch>
                    <Match when={promo.field_segment_mkg.name === "Access"}>
                      <strong class="inline">
                        Avec
                        <LogoAccess />
                        <span class="name">{promo.field_segment_mkg.name}</span>
                      </strong>
                      <strong>je transforme mon loyer en propriété</strong>
                    </Match>
                    <Match when={promo.field_segment_mkg.name === "Avantages"}>
                      <strong>
                        <LogoCogedimAvantagesVertical1 />
                        <LogoCogedimAvantagesVertical2 />
                        <span class="name">
                          Cogedim {promo.field_segment_mkg.name}
                        </span>
                      </strong>
                      <strong>redonne le pouvoir d'investir</strong>
                    </Match>
                    <Match
                      when={
                        promo.field_segment_mkg.name === "Cogedim Signature"
                      }
                    >
                      <LogoCogedimSignature />
                    </Match>
                  </Switch>

                  <Show when={benefitsLegals()}>
                    <button
                      onClick={() => setDialogIsActive(true)}
                      data-test="cta-legals"
                    >
                      Voir les conditions
                    </button>
                  </Show>
                </p>
                <div class="benefits" data-test="benefits">
                  <For each={promo.field_benefits}>
                    {(benefit) => {
                      return (
                        <div
                          class={`benefit ${benefit.field_style.name}`}
                          data-test="benefit"
                          innerHTML={benefit.field_text.value}
                        />
                      );
                    }}
                  </For>
                </div>
              </div>
            );
          }}
        </For>
      </section>
      <Show when={dialogIsActive()} keyed>
        <Dialog
          title="Conditions"
          closingCallback={setDialogIsActive}
          horizontalAlignment="center"
          verticalAlignment="center"
        >
          <div innerHTML={benefitsLegals()} />
        </Dialog>
      </Show>
    </>
  );
}
